import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class RequiredDocumentValueService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/required';
    }

    create(documentId, data) {
        let url = `${this.#api}/${documentId}/document-value`
        return apiService.post(url, data)
    }

    paginate(documentId, data = {}, index = null) {
        let url = `${this.#api}/${documentId}/document-value`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    show(documentId, id) {
        let url = `${this.#api}/${documentId}/document-value/${id}`
        return apiService.get(url)
    }

    update(documentId, id, data) {
        let url = `${this.#api}/${documentId}/document-value/${id}/update`;
        return apiService.post(url, data)
    }

    delete(documentId, id) {
        let url = `${this.#api}/${documentId}/document-value/${id}`
        return apiService.delete(url);
    }

    getAllActive() {
        let url = `${this.#api}/get/all`
        return apiService.get(url)
    }

    createDocumentFor(type, data) {
        let url = `${this.#api}-document-value/create-or-update/for/${type}`
        return apiService.post(url, data);
    }

    sort(data=[]){
        let url = `${this.#api}/document-value-sort`;
        return apiService.post(url, data);
    }
}
