import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class RequiredDocumentService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/required-document';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    getByCountry(countryId) {
        let url = `${this.#api}/country/${countryId}`
        return apiService.get(url)
    }

    sort(data = []) {
        let url = `${this.#api}/sort/position`;
        return apiService.post(url, data);
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getAllActive() {
        let url = `${this.#api}/get/all`
        return apiService.get(url)
    }

    getByInstitution(institutionId) {
        let url = `${this.#api}/institution/${institutionId}`
        return apiService.get(url)
    }

    getByProgram(programId) {
        let url = `${this.#api}/program/${programId}`
        return apiService.get(url)
    }

}
