<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Required Document under {{ required_document.title }}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Required Document
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="manageRequiredDocumentValue({},'open')"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add New To Group
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="search.title" outlined dense label="Search by title"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-btn class="btn btn-primary text-white" depressed @click="getValues">Search</v-btn>
                  <v-btn class="btn btn-standard text-white ml-2" depressed @click="search ={}">Reset</v-btn>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12">
                <template v-if="loading">
                  <v-skeleton-loader
                      type="table-thead"
                  >
                  </v-skeleton-loader>

                  <v-skeleton-loader
                      type="table-row-divider@25"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <table class="table">
                    <thead>
                    <th>Title</th>
                    <th>Is Required</th>
                    <th>Document Type</th>
                    <th>Status</th>
                    <th>Reference Document</th>
                    <th>Action</th>
                    </thead>
                    <template v-if="documents && documents.length>0">
                      <draggable
                          v-model="documents"
                          @change="sort"
                          @start="drag=true"
                          tag="tbody"
                          @end="drag=false"
                      >
                        <tr v-for="(document, index) of documents" :key="index">
                          <td><i class="fa fa-sort"></i> {{ document.title }}</td>
                          <td>
                            <i class="fas" :class="document.is_required?'fa-check text-success':'fa-ban text-danger'"></i>
                          </td>
                          <td>
                            <span class="text-uppercase">{{ document.document_type }}</span>
                          </td>
                          <td>
                            <i class="fas" :class="document.is_active?'fa-check text-success':'fa-ban text-danger'"></i>
                          </td>
                          <td>
                            <template v-if="document.image_path && document.image_path['real']">
                              <img :src="document.image_path['real']" :alt="document.title" height="60px">
                            </template>
                            <template v-else>
                              -
                            </template>
                          </td>
                          <td>
                            <b-dropdown
                                size="sm"
                                variant="link"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                no-caret
                                right
                                no-flip>
                              <template v-slot:button-content>
                                <i class="ki ki-bold-more-hor"></i>
                              </template>
                              <!--begin::Navigation-->
                              <div class="navi navi-hover min-w-md-250px">

                                <b-dropdown-text tag="div" class="navi-item">
                                  <a class="navi-link" @click="manageRequiredDocumentValue(document, 'open')">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                    <span class="navi-text">Edit</span>
                                  </a>
                                </b-dropdown-text>
                                <b-dropdown-text tag="div" class="navi-item">
                                  <a class="navi-link" @click.prevent="deleteRequiredDocumentValue(document.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                    <span class="navi-text">Delete</span>
                                  </a>
                                </b-dropdown-text>
                              </div>
                              <!--end::Navigation-->
                            </b-dropdown>
                          </td>
                        </tr>
                      </draggable>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="50">No data found</td>
                      </tr>
                    </template>
                  </table>
                </template>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>

    <create-or-update v-if="document_id" ref="create-or-update" :document_id="document_id" :dialog="value_dialog"
                      @close_dialog="manageRequiredDocumentValue({}, 'close')"
    ></create-or-update>
  </v-app>
</template>
<script>

import RequiredDocumentService from "@/services/required-document/RequiredDocumentService";
import RequiredDocumentValueService from "@/services/required-document/RequiredDocumentValueService";
import CreateOrUpdate from "./CreateOrUpdate";
import draggable from "vuedraggable";

const documentValueService = new RequiredDocumentValueService();
const documentService = new RequiredDocumentService();

export default {
  name: "Index.ve",
  components: {
    CreateOrUpdate,
    draggable
  },
  data() {
    return {
      documents: [],
      loading: false,
      value_dialog: false,
      search: {},
      required_document: {},
      isSorting: false,
      drag: false,
    }
  }, computed: {
    document_id() {
      return this.$route.params.id;
    }
  }, mounted() {
    this.getValues()
    this.getRequiredDocument();
  },
  methods: {
    sort(){
      this.isSorting = true;
      documentValueService
          .sort(this.documents)
          .then(response => {
            this.getValues();
            this.isSorting = false;
            this.$snotify.success('Document Sort !!');
          })
          .catch(err => {});
    },
    getRequiredDocument() {
      documentService.show(this.document_id).then(response => {
        this.required_document = response.data.document
      })
    },
    getValues() {
      this.loading = true
      documentValueService.paginate(this.document_id, this.search).then(response => {
        this.documents = response.data.data
      }).catch(() => {
        this.documents = [];
      }).finally(this.loading = false)
    },
    deleteRequiredDocumentValue(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            documentValueService.delete(this.document_id, id).then(response => {
              this.$snotify.success('Document Value deleted successfully');
            }).catch(error => {
              this.$snotify.error('Something went wrong please try again later');
            }).finally(() => {
              this.loading = false
              this.getValues()
            })
          }
        }
      });
    },
    manageRequiredDocumentValue(item = {}, type = 'open') {
      if (type == 'open') {
        this.value_dialog = true
        if (item && item.id)
          this.$refs['create-or-update'].assignValue(item)
      } else {
        this.value_dialog = false
        this.getValues();
      }
    }
  }
}
</script>

<style scoped>

</style>