<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title>{{ title }} Required Document Value</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field outlined dense v-model="value.title" :error="$v.value.title.$error">
              <template v-slot:label>
                Title <span class="text-danger">*</span>
              </template>
            </v-text-field>
            <span class="text-danger" v-if="$v.value.title.$error">This field is required</span>
          </v-col>
          <v-col cols="12">
            <v-select :items="document_types"
                      outlined dense
                      v-model="value.document_type"
                      item-text="title"
                      item-value="value"
                      label="Document Type"
            ></v-select>
            <span class="text-danger" v-if="$v.value.document_type.$error">This field is required</span>
          </v-col>

          <v-col cols="12">
            <v-text-field outlined dense v-model="value.link">
              <template v-slot:label>
                Link <span class="text-danger"></span>
              </template>
            </v-text-field>
            <span class="text-danger" v-if="$v.value.link.$error">Value must be a valid url</span>
          </v-col>
          <v-col cols="12">
            <v-file-input v-model="value.reference_document" label="Reference Document" outlined dense></v-file-input>
          </v-col>
          <v-col cols="12">
            <ckeditor
                :config="editorConfig"
                v-model="value.description"
            ></ckeditor>
          </v-col>
          <v-col cols="12">
            <v-switch label="Required" v-model="value.is_required"></v-switch>
            <v-switch label="Status" v-model="value.is_active"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn btn-standard" depressed @click="refreshForm()">Close</v-btn>
        <v-btn class="btn btn-primary text-white" depressed @click="createOrUpdate">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, url} from "vuelidate/lib/validators";
import RequiredDocumentValueService from "@/services/required-document/RequiredDocumentValueService";

const documentValueService = new RequiredDocumentValueService();
export default {
  name: "CreateOrUpdate",
  props: ['document_id', 'dialog'],
  validations: {
    value: {
      title: {required},
      document_type: {required},
      link: {url}
    }
  },
  data() {
    return {
      value: {
        title: null,
        document_type: null,
        is_active: true
      },
      edit: false,
      title: 'Create',
      document_types: [
        {title: 'Text', 'value': 'text'},
        {title: 'File', value: 'file'}
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'Source', '-', 'Save', 'NewPage', 'DocProps', 'Preview', 'Print', '-', 'Templates'],
        ]
      }
    }
  },
  methods: {
    assignValue(value) {
      this.title = "Update"
      this.edit = true
      this.value = value
    },
    refreshForm() {
      this.value = {
        title: null
      }
      this.edit=false
      this.$v.value.$reset();
      this.$emit('close_dialog');
    },
    createOrUpdate() {
      this.$v.value.$touch();
      if (this.$v.value.$error) {
        setTimeout(() => {
          this.$v.value.$reset();
        }, 3000)
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.update(fd)
        } else {
          this.create(fd);
        }
      }
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.value) {
        if (
            key == "reference_document" &&
            this.value["reference_document"] &&
            this.value["reference_document"] != null
        ) {
          fd.append("reference_document", this.value['reference_document']);
        } else {
          fd.append(key, this.value[key] ? this.value[key] == true ? 1 : this.value[key] : "");
        }
      }
      return fd
    },
    create(fd) {
      this.loading = true
      documentValueService.create(this.document_id, fd).then(response => {
        this.$snotify.success("Required Document Value create successfully")
        this.refreshForm();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later")
      }).finally(this.loading = true)
    },
    update(fd) {
      this.loading = true
      documentValueService.update(this.document_id, this.value.id, fd).then(response => {
        this.$snotify.success("Required Document Value create successfully")
        this.refreshForm();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later")
      }).finally(this.loading = true)
    },
  }
}
</script>

<style scoped>

</style>
